import React from "react";
import { Stack, Box, Typography } from "@mui/material";
import "@fontsource/montserrat";
import HomeTab from "./components/HomePage";
import AboutUs from "./components/AboutUs";
import ContactUs from "./components/ContactUs";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link,
  useLocation,
} from "react-router-dom";
import Footer from "./components/Footer";
import "./scrollbar.css";

function App() {
  return (
    <Router>
      <Box
        sx={{ display: "flex", flexDirection: "column", minHeight: "100vh" }}
      >
        <Box
          sx={{
            boxShadow:
              "0 4px 6px -1px rgba(0,0,0,0.1), 0 2px 4px -1px rgba(0,0,0,0.06)",
          }}
        >
          <Stack
            position={"sticky"}
            gap={"7.18rem"}
            justifyContent={"left"}
            sx={{ p: "0.68rem 5.0rem" }}
            direction={"row"}
          >
            <a href={"https://www.fusionnex.tech"}>
              <img
                src="./fusionnex-logo.svg"
                style={{ height: "2.9rem" }}
                alt="fusionnex logo"
              />
            </a>
            <Stack
              direction={"row"}
              justifyContent={"left"}
              gap={"3rem"}
              alignItems={"center"}
            >
              <NavLink to="/">Home</NavLink>
              <NavLink to="/about">About Us</NavLink>
              <NavLink to="/connect">Connect</NavLink>
            </Stack>
          </Stack>
        </Box>

        <Box sx={{ flex: "1", overflowY: "auto", scrollbarWidth: "thin" }}>
          <Routes>
            <Route path="/" element={<HomeTab />} />
            <Route path="/about" element={<AboutUs />} />
            <Route path="/connect" element={<ContactUs />} />
          </Routes>
        </Box>
        <Footer />
      </Box>
    </Router>
  );
}

const NavLink = ({ to, children }) => {
  const { pathname } = useLocation();
  const isActive = pathname === to;

  return (
    <Link to={to} style={{ textDecoration: "none" }}>
      <Typography
        sx={{
          fontFamily: "Montserrat, sans-serif",
          fontWeight: "590",
          fontSize: "1rem",
          color: isActive ? "rgba(28, 28, 28, 1)" : "rgba(95, 101, 112, 1)",
          cursor: "pointer",
          borderBottom: isActive ? "2px solid rgba(28, 28, 28, 1)" : "none",
        }}
      >
        {children}
      </Typography>
    </Link>
  );
};

export default App;
