import React, { useState, useEffect } from "react";
import "./styles.css";
import { Stack, Box, Typography } from "@mui/material";

const TechStackSlide2 = ({ isActive }) => {
  return (
    <Stack
      direction={"column"}
      justifyContent={"center"}
      alignItems={"center"}
      className={`slide ${isActive ? "active" : ""}`}
    >
      <Stack direction={"row"} gap={"4.5rem"}>
        {/* Assuming these images are in the public folder */}
        <img src="/python.svg" alt={"python logo"} />
        <img src="/dotnet.svg" alt={"dotnet logo"} />
        <img src="/nodejs.svg" alt={"nodejs logo"} />
      </Stack>{" "}
      <Typography
        sx={{
          display: "flex",
          justifyContent: "center",
          fontSize: "1.5rem",
          fontFamily: "Montserrat",
          fontWeight: "500",
          letterSpacing: "2.5px",
          color: "#fff",
          marginTop: "1.5rem",
        }}
      >
        {" "}
        BACKEND
      </Typography>
    </Stack>
  );
};

const TechStackSlide1 = ({ isActive }) => {
  return (
    <Stack
      direction={"column"}
      justifyContent={"center"}
      alignItems={"center"}
      className={`slide ${isActive ? "active" : ""}`}
    >
      <Stack direction={"row"} gap={"4rem"}>
        {/* Assuming these images are in the public folder */}
        <img src="/reactjs.svg" alt={"React logo"} />
        <img src="/vuejs.svg" alt={"vuejs logo"} />
        <img src="/donNetCore.svg" alt={"DotNetCore logo"} />
      </Stack>{" "}
      <Typography
        sx={{
          display: "flex",
          justifyContent: "center",
          fontSize: "1.5rem",
          fontFamily: "Montserrat",
          fontWeight: "500",
          letterSpacing: "2.5px",
          color: "#fff",
          marginTop: "1.5rem",
        }}
      >
        {" "}
        FRONTEND
      </Typography>
    </Stack>
  );
};

const TechStackSlide3 = ({ isActive }) => {
  return (
    <Stack
      direction={"column"}
      justifyContent={"center"}
      alignItems={"center"}
      className={`slide ${isActive ? "active" : ""}`}
    >
      <Stack direction={"row"} gap={"4.5rem"}>
        {/* Assuming these images are in the public folder */}
        <Stack>
          <img src="/mongoDB.svg" alt={"mongo logo"} />
          <Typography
            sx={{
              fontFamily: "Montserrat",
              fontSize: "1rem",
              fontWeight: "500",
            }}
          >
            MongoDB
          </Typography>
        </Stack>
        <Stack>
          <img src="/oracle.svg" alt={"oracle logo"} />
          <Typography
            sx={{
              fontFamily: "Montserrat",
              fontSize: "1rem",
              fontWeight: "500",
              marginTop: "12px",
              display: "flex",
              justifyContent: "center",
            }}
          >
            Oracle
          </Typography>
        </Stack>
        <Stack>
          <img src="/mySQL.svg" alt={"SQL logo"} />
          <Typography
            sx={{
              fontFamily: "Montserrat",
              fontSize: "0.9rem",
              // width: "100%",
              // fontWeight: "500",
              marginTop: "12px",
              display: "flex",
              justifyContent: "center",
            }}
          >
            MS SQL Server
          </Typography>
        </Stack>
      </Stack>{" "}
      <Typography
        sx={{
          display: "flex",
          justifyContent: "center",
          fontSize: "1.5rem",
          fontFamily: "Montserrat",
          fontWeight: "500",
          letterSpacing: "2.5px",
          color: "#fff",
          marginTop: "1.5rem",
        }}
      >
        {" "}
        DATABASE
      </Typography>
    </Stack>
  );
};

const AboutUs = () => {
  const [activeSlide, setActiveSlide] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setActiveSlide((prevSlide) => (prevSlide + 1) % 3);
    }, 3000); // Change slide every 3 seconds

    return () => clearInterval(interval);
  }, []);
  return (
    <>
      <Box sx={{ padding: "5rem 3.4rem" }}>
        <Stack direction={"row"} justifyContent={"space-around"}>
          <Stack direction={"column"}>
            <Typography
              sx={{
                fontSize: "2rem",
                fontFamily: "Montserrat",
                fontWeight: "600",
              }}
            >
              Here, your Ideas meet our Tech
            </Typography>
            <Typography
              sx={{
                fontSize: "1.2rem",
                fontFamily: "Montserrat",
                fontWeight: "500",
                marginTop: "0.5rem",
              }}
            >
              Fusion Nex is a center of ideas engaged with expert’s
              <br /> tech-hands to build your Brand, shape in excellence.
              <br /> We empathize with your needs and relate with the <br />
              service to together serve the society.{" "}
            </Typography>
            <Typography
              sx={{
                fontSize: "1.2rem",
                fontFamily: "Montserrat",
                fontWeight: "500",
                marginTop: "1.5rem",
              }}
            >
              Design processes, Develop your Website, Mobile <br /> Applications
              & Softwares.
            </Typography>
            <Typography
              sx={{
                fontSize: "1.2rem",
                fontFamily: "Montserrat",
                fontWeight: "500",
                marginTop: "1.5rem",
              }}
            >
              Begin with Us. Share your vision.{" "}
              <a
                href={"/connect"}
                style={{ textDecoration: "none", color: "#000" }}
              >
                <strong>Connect Now!</strong>
              </a>
            </Typography>
          </Stack>
          <Stack
            direction={"row"}
            justifyContent={"center"}
            alignItems={"center"}
          >
            <img
              src="./fusionnex-logo.svg"
              style={{ height: "11rem", width: "27rem" }}
              alt={"Fusionnex Logo"}
            />
          </Stack>
        </Stack>
      </Box>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginBottom: "4rem",
        }}
      >
        {" "}
        <Box
          sx={{
            flexDirection: "column",
            backgroundColor: "#000",
            p: "1rem 2.3rem",
            width: "30.6rem",
            height: "16rem", // Adjust the height as needed
            color: "#fff",
            borderRadius: "50px",
            boxShadow: "0px 0px 30px 20px #B3B5BC80",
            position: "relative",
          }}
        >
          <Stack
            direction={"column"}
            justifyContent={"center"}
            alignItems={"center"}
          >
            {" "}
            <Typography sx={{ fontSize: "1.8rem", fontFamily: "Montserrat" }}>
              Our Tech Stack
            </Typography>
          </Stack>
          <Stack
            direction={"column"}
            justifyContent={"center"}
            alignItems={"center"}
          >
            <Stack
              sx={{
                marginTop: "7.5rem",
                position: "relative",
                justifyContent: "center",
                direction: "row",
                alignContent: "center",
              }}
            >
              <div
                className="slide-container"
                style={{
                  position: "absolute",
                  width: "100%",
                  height: "60%",
                }}
              >
                <TechStackSlide1 isActive={activeSlide === 0} />
                <TechStackSlide2 isActive={activeSlide === 1} />
                <TechStackSlide3 isActive={activeSlide === 2} />
              </div>
            </Stack>
          </Stack>
        </Box>
      </div>{" "}
      {/* <Footer /> */}
    </>
  );
};

export default AboutUs;
