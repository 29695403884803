import React from "react";

const Footer = () => {
  const styles = {
    footer: {
      fontFamily: "Montserrat, sans-serif",
      backgroundColor: "rgba(28, 28, 28, 1)",
      color: "#fff",
      padding: "10px 20px", // Adjust padding to reduce height
      textAlign: "center",
      borderTop: "1px solid #ccc",
    },

    copyright: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
      alignItems: "center",
      fontSize: "0.85rem",
      fontFamily: "Montserrat, sans-serif",
    },
  };

  return (
    <footer style={styles.footer}>
      <p style={styles.copyright}>
        © 2024 &nbsp;
        <a
          href={"https://www.fusionnex.tech"}
          style={{ color: "#fff", textDecoration: "none" }}
        >
          {" "}
          FusionNex Tech
        </a>
        &nbsp;:&nbsp; All rights reserved.
      </p>
    </footer>
  );
};

export default Footer;
