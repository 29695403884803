import React, { useState, useEffect } from "react";
import {
  Box,
  Stack,
  Typography,
  TextField,
  Button,
  IconButton,
  InputAdornment,
} from "@mui/material";
import axios from "axios";
import FormSuccess from "./success-page";

const ContactUs = () => {
  const [stage, setStage] = useState("Name");
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: "",
  });
  const [emailError, setEmailError] = useState(false);
  const [messageError, setMessageError] = useState(false);

  const mainText = [
    "Owner of your [dream] company?",
    "How should we connect you?",
    "Tell your story...",
  ];

  const placeholders = [
    "Full Name",
    "Email Address",
    "Describe your vision in 500 characters",
  ];

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
    // Resetting errors on input change
    if (name === "email") setEmailError(false);
    if (name === "message") setMessageError(false);
  };

  console.log(process.env.REACT_HOST_URL, "REACT_HOST_URL");
  const handleNext = () => {
    if (stage === "Name") {
      if (!formData.name.trim()) {
        return;
      }
      setStage("Email");
    } else if (stage === "Email") {
      if (!formData.email.trim()) {
        return;
      }
      if (!validateEmail(formData.email)) {
        setEmailError(true);
        return;
      }
      setStage("Description");
    } else {
      if (!formData.message.trim()) {
        return;
      }
      if (formData.message.length > 500) {
        setMessageError(true);
        return;
      }
      handleSubmit();
    }
  };

  const handleSubmit = async () => {
    try {
      axios
        .post(
          "https://fusionnex.azurewebsites.net/api/Contact/NewContact",
          formData
        )
        .then(() => {
          setStage("Success");
        });
    } catch (err) {
      console.error(err);
    }
  };

  const handleBack = () => {
    if (stage === "Email") {
      setStage("Name");
    } else if (stage === "Description") {
      setStage("Email");
    }
  };

  useEffect(() => {
    const handleKeyPress = (event) => {
      if (event.key === "Enter") {
        if (stage === "Description") {
          handleSubmit();
        } else {
          handleNext();
        }
      } else if (event.altKey && event.key === "Enter") {
        if (stage === "Description") {
          handleSubmit();
        }
      }
    };

    document.addEventListener("keydown", handleKeyPress);

    return () => {
      document.removeEventListener("keydown", handleKeyPress);
    };
  }, [stage, formData]);

  const validateEmail = (email) => {
    // Regular expression for email validation
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(email);
  };

  return stage !== "Success" ? (
    <>
      <Box sx={{ p: "3rem 3.4rem 5rem 3.4rem", overflowY: "hidden" }}>
        <Stack
          direction="column"
          gap={stage === "Description" ? "2rem" : "4rem"}
        >
          <Typography
            sx={{
              fontFamily: "Montserrat",
              fontWeight: "600",
              fontSize: "2rem",
              letterSpacing: "0.2rem",
            }}
          >
            Next is You, Connect Now!
          </Typography>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Stack direction="column" gap="1rem">
              <Typography
                sx={{
                  fontFamily: "Montserrat",
                  fontWeight: "500",
                  fontSize: "2rem",
                  letterSpacing: "0.01rem",
                }}
              >
                {mainText[stage === "Name" ? 0 : stage === "Email" ? 1 : 2]}
              </Typography>

              <TextField
                value={
                  formData[
                    stage === "Name"
                      ? "name"
                      : stage === "Email"
                      ? "email"
                      : "message"
                  ]
                }
                name={
                  stage === "Name"
                    ? "name"
                    : stage === "Email"
                    ? "email"
                    : "message"
                }
                onChange={handleInputChange}
                hiddenLabel
                variant="outlined"
                placeholder={
                  placeholders[stage === "Name" ? 0 : stage === "Email" ? 1 : 2]
                }
                multiline={stage === "Description"}
                rows={5}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <Typography
                        sx={{
                          fontSize: "0.9rem",
                          fontFamily: "Montserrat",
                          fontWeight: "500",
                          marginTop: "0.5rem",
                          fontStyle: "normal",

                          color:
                            (stage === "Email" && emailError) ||
                            (stage === "Description" && messageError)
                              ? "red"
                              : null,
                        }}
                      >
                        {stage === "Description" ? (
                          <span>
                            Press <strong>Alt + Enter</strong> <br /> For{" "}
                            <strong>Begin Your Journey</strong>
                          </span>
                        ) : (
                          <span>
                            Press <strong>Enter</strong> <br /> For{" "}
                            <strong>Next</strong>
                          </span>
                        )}
                      </Typography>
                    </InputAdornment>
                  ),
                }}
                sx={{
                  ".MuiOutlinedInput-root": {
                    borderRadius: "10px",
                    fontFamily: "Montserrat",
                    fontWeight: "500",
                    letterSpacing: "0.1rem",
                    fontSize:
                      stage === "Description" && !formData["message"]
                        ? "1.6rem"
                        : "1.6rem",
                    width: "34rem",
                    fontStyle:
                      stage === "Description" && !formData["message"]
                        ? "italic"
                        : "none",
                    border:
                      (stage === "Email" && emailError) ||
                      (stage === "Description" && messageError)
                        ? "3px solid red"
                        : "3px solid #000",
                    "& fieldset": { border: "none" },
                    "&:hover fieldset": { border: "none" },
                    "&.Mui-focused fieldset": { border: "none" },
                    input: {
                      "&::placeholder": {
                        textOverflow: "ellipsis !important",
                        color: "#666",
                        fontSize: "1.6rem",
                        fontStyle: "italic",
                        fontWeight: "500",
                      },
                    },
                    "& .MuiInputAdornment-positionEnd": {
                      position: "absolute",
                      top: "calc(100% - 35px)",
                      right: "10px",
                    },
                  },
                }}
              />

              {stage === "Email" && emailError && (
                <Typography sx={{ color: "red" }}>
                  Please enter a valid email address.
                </Typography>
              )}
              {stage === "Description" && messageError && (
                <Typography sx={{ color: "red" }}>
                  Message should not exceed 500 characters.
                </Typography>
              )}
              <Stack direction="row" justifyContent="right" gap="0.6rem">
                {stage !== "Name" && (
                  <IconButton aria-label="back" onClick={handleBack}>
                    <img
                      src="leftIcon.svg"
                      style={{ height: "3rem", width: "3rem" }}
                      alt={"leftIcon"}
                    />
                  </IconButton>
                )}
                <Button
                  onClick={handleNext}
                  sx={{
                    p: "0.7rem 1rem",
                    marginTop: "0.5rem",
                    maxHeight: "3rem",
                    backgroundColor:
                      formData[
                        stage === "Name"
                          ? "name"
                          : stage === "Email"
                          ? "email"
                          : "message"
                      ].trim() === ""
                        ? "#D3D3D3"
                        : "#000",
                    textTransform: "none",
                    color: "#fff",
                    fontSize: "1rem",
                    fontWeight: "700",
                    borderRadius: "12.5rem",
                    fontFamily: "Montserrat",
                    "&:hover": {
                      backgroundColor:
                        formData[
                          stage === "Name"
                            ? "name"
                            : stage === "Email"
                            ? "email"
                            : "message"
                        ].trim() === ""
                          ? "#D3D3D3"
                          : "#000",
                      color: "#fff",
                    },
                  }}
                >
                  {stage === "Description"
                    ? "Let’s begin your journey"
                    : "Next"}
                </Button>
              </Stack>
            </Stack>
          </Box>
        </Stack>
      </Box>
    </>
  ) : (
    <FormSuccess />
  );
};

export default ContactUs;
