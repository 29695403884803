import React from "react";
import { Box, Stack, Typography } from "@mui/material";
import Footer from "../Footer";

const FormSuccess = () => {
  return (
    <>
      <Box sx={{ p: "5rem 3.4rem", overflowY: "hidden" }}>
        <Stack direction="column" gap={"2rem"} sx={{ overflowY: "hidden" }}>
          <Typography
            sx={{
              fontFamily: "Montserrat",
              fontWeight: "600",
              fontSize: "2.5rem",
              letterSpacing: "0.2rem",
            }}
          >
            Wohoo, Congratulations!!!
          </Typography>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Stack direction="column">
              <Typography
                sx={{
                  fontFamily: "Montserrat",
                  fontWeight: "600",
                  fontSize: "2.5rem",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                Your Dream gonna have an
              </Typography>
              <Typography
                sx={{
                  fontFamily: "Montserrat",
                  fontWeight: "600",
                  fontSize: "2.5rem",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                Execution Plan;)
              </Typography>
              <Typography
                sx={{
                  fontFamily: "Montserrat",
                  fontWeight: "600",
                  fontSize: "1.544rem",
                  color: "rgba(95, 101, 112, 1)",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  marginTop: "1rem",
                  letterSpacing: "0.1rem",
                }}
              >
                Thank for contacting us, <br />
              </Typography>
              <Typography
                sx={{
                  fontFamily: "Montserrat",
                  fontWeight: "600",
                  fontSize: "1.544rem",
                  color: "rgba(95, 101, 112, 1)",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  letterSpacing: "0.1rem",
                }}
              >
                will email you soon...
              </Typography>
            </Stack>
          </Box>
        </Stack>
      </Box>
    </>
  );
};

export default FormSuccess;
