import React, { useState, useEffect } from "react";
import { Box, Typography } from "@mui/material";
import { Animated } from "react-animated-css";
import "@fontsource/montserrat";
import Footer from "../Footer";

const AnimatedQuotes = ({ quotes, duration = 3000 }) => {
  const [index, setIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setIndex((prevIndex) => (prevIndex + 1) % quotes.length);
    }, duration);
    return () => clearInterval(interval);
  }, [quotes.length, duration]);

  const currentDate = new Date().toLocaleDateString("en-US", {
    day: "2-digit",
    month: "short",
    year: "numeric",
  });

  return (
    <div
      style={{
        fontSize: "2.5rem",
        whiteSpace: "nowrap",
      }}
    >
      <Animated
        animationIn="fadeIn"
        animationOut="fadeOut"
        isVisible={true}
        animationInDuration={duration / 2}
        animationOutDuration={duration / 2}
      >
        <div
          style={{
            width: "100%",
            textAlign: "center",
            fontFamily: "Montserrat",
            fontWeight: "600",
          }}
        >
          {quotes[index]}
          {index === 2 && (
            <Typography
              style={{
                marginTop: "0.5rem",
                fontFamily: "Montserrat",
                fontSize: "1.68rem",
                fontWeight: "600",
                color: "rgba(95, 101, 112, 1)",
              }}
            >
              Today is {currentDate}
            </Typography>
          )}
          {index === 3 && (
            <Typography
              style={{
                marginTop: "0.5rem",
                fontFamily: "Montserrat",
                fontSize: "1.68rem",
                fontWeight: "600",
                color: "rgba(95, 101, 112, 1)",
              }}
            >
              Bring at Fusion Nex
            </Typography>
          )}
          {index === 4 && (
            <Typography
              style={{
                marginTop: "0.5rem",
                fontFamily: "Montserrat",
                fontSize: "1.68rem",
                fontWeight: "600",
                color: "rgba(95, 101, 112, 1)",
              }}
            >
              Your Success will be Our Story
            </Typography>
          )}
        </div>
      </Animated>
    </div>
  );
};

const HomeTab = () => {
  const quoteLines = [
    "Dreams change Lives",
    "People dream to become Better",
    "When did you think to Startup?",
    "Having an Idea?",
    "Design, Develop, Build with Us",
  ];

  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "70vh",
          textAlign: "center",
          fontSize: "2.5rem",
          marginBottom: "3rem",
        }}
      >
        <AnimatedQuotes quotes={quoteLines} duration={4000} />
      </Box>
    </>
  );
};

export default HomeTab;
